const invoiceStatus = {
    CREATED: 0,
    SENT: 1,
    CLIENT_NOTIFIED: 2,
    CASHED: 3,
    FREEZED: 4,
}

const sheetsStatus = {
    CREATED: 0,
    INVOICED: 1,
    CASHED: 2,
    FREEZED: 3,
}

const invoiceReverseStatus = [
    "En attente d'envois",
    "En attente d'encaissement",
    "Client relancé",
    "Encaissé",
    "Facture bloquée",
]

const sheetsReverseStatus = [
    "Non-facturé",
    "Facturé",
    'Encaissé',
    "Bloqué",
]

const dataScope = {
    CLIENT: 0,
    MISSION: 1,
}

export {
    invoiceStatus,
    sheetsStatus,
    invoiceReverseStatus,
    sheetsReverseStatus,
    dataScope,
}