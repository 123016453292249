import Vue from 'vue'
import VueRouter from 'vue-router'
import { firebase } from '../firebase.js';
// import store from '../store/index';
import DashboardView from '../views/DashboardView.vue'
import { getCookie } from '@/utils/helpers.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/select-account',
    name: 'select-account',
    component: () => import('../views/SelectAccount.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('../views/VerifyPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/acceptInvite',
    name: 'accept',
    component: () => import('../views/AcceptInvite.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/users/invite-user',
    name: 'invite-user',
    component: () => import('../views/InviteUser.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
    }
  },
  // USER
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/UsersView.vue'),
    meta: {
      requiresAuth: true,
    }
  }, {
    path: '/users/:userId/:initPage?',
    name: 'user',
    props: true,
    component: () => import('../views/UserPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  // CLIENT
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/ClientsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/clients/:clientId',
    name: 'client',
    props: true,
    component: () => import('../views/ClientPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/clients/:clientId/nouvelle-mission',
    name: 'nouvelle-mission-client',
    props: true,
    component: () => import('../views/NewMissionPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/clients/nouveau-client',
    name: 'nouveau-client',
    props: true,
    component: () => import('../views/NewClientPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  // MISSION
  {
    path: '/missions',
    name: 'missions',
    component: () => import('../views/MissionsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/missions/:missionId',
    name: 'mission',
    props: true,
    component: () => import('../views/MissionPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/missions/:missionId/modifier',
    name: 'edit-mission',
    props: true,
    component: () => import('../views/EditMissionPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/missions/:missionId/nouvelle-facture',
    name: 'add-facture-mission',
    props: true,
    component: () => import('../views/NewInvoicePage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/missions/nouvelle-mission',
    name: 'nouvelle-mission',
    props: true,
    component: () => import('../views/NewMissionPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/clients/:clientId/nouvelle-facture',
    name: 'add-facture-client',
    props: true,
    component: () => import('../views/NewInvoicePage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  // SHEETS
  {
    path: '/sheets',
    name: 'sheets',
    component: () => import('../views/SheetsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/sheets/nouveau-sheet',
    name: 'add-sheet',
    component: () => import('../views/NewSheetPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/sheets/:sheetId',
    name: 'sheet',
    props: true,
    component: () => import('../views/SheetPage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  // INVOICE
  {
    path: '/factures',
    name: 'factures',
    component: () => import('../views/InvoicesView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factures/:invoiceId',
    name: 'facture',
    props: true,
    component: () => import('../views/InvoicePage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factures/:invoiceId/avoirs/nouvel-avoir',
    name: 'new-credit',
    props: true,
    component: () => import('../views/NewCreditView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factures/:invoiceId/avoirs/:creditId',
    name: 'credit',
    props: true,
    component: () => import('../views/CreditView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factures/:invoiceId/avoirs',
    name: 'credits',
    props: true,
    component: () => import('../views/CreditsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factures/nouvelle-facture',
    name: 'add-facture',
    props: true,
    component: () => import('../views/NewInvoicePage.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      requiresAuth: true,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = await firebase.getCurrentUser();
  const isVerified = user ? user.emailVerified : false;
  const hasAccountUid = getCookie('accountuid') != '';

  console.log('hasAccountUid', hasAccountUid);

  console.log('isVerified', isVerified);

  // if (user) {
  //   const account = (await db.collection('users').doc(user.uid).get()).data().account;
  //   store.commit('setAccountUid', account);
  // }
  // if (!user) {
  //   store.commit('setAccountUid', '');
  // }

  // if (!hasAccountUid) {
  //   console.log('no account uid');
  //   next('/select-account');
  // }

  const normalRoute = () => {
    console.log('normal route', user, isVerified, hasAccountUid);
    if (user && isVerified && hasAccountUid) {
      next();
    } else if (!user) {
      next('login');
    } else if (!isVerified) {
      next('verify');
    } else if (!hasAccountUid) {
      next('select-account');
    }
  }

  // Fix guards

  switch (to.name) {
    case 'select-account':
      if (user && isVerified) {
        next();
      } else {
        next('');
      }
      break;
    case 'login':
      if (!user) {
        next();
      } else {
        next('')
      }
      break;
    case 'verify':
      if (user && !isVerified) {
        next();
      } else if (user && isVerified) {
        next('');
      } else {
        next('login');
      }
      break;
    case 'dashboard':
      normalRoute();
      break;
    case 'users':
      normalRoute();
      break;
    case 'user':
      normalRoute();
      break;
    case 'clients':
      normalRoute();
      break;
    case 'client':
      normalRoute();
      break;
    case 'nouvelle-mission-client':
      normalRoute();
      break;
    case 'nouveau-client':
      normalRoute();
      break;
    case 'missions':
      normalRoute();
      break;
    case 'mission':
      normalRoute();
      break;
    case 'edit-mission':
      normalRoute();
      break;
    case 'add-facture-mission':
      normalRoute();
      break;
    case 'nouvelle-mission':
      normalRoute();
      break;
    case 'add-facture-client':
      normalRoute();
      break;
    case 'sheets':
      normalRoute();
      break;
    case 'add-sheet':
      normalRoute();
      break;
    case 'sheet':
      normalRoute();
      break;
    case 'factures':
      normalRoute();
      break;
    case 'facture':
      normalRoute();
      break;
    case 'credits':
      normalRoute();
      break;
    case 'credit':
      normalRoute();
      break;
    case 'new-credit':
      normalRoute();
      break;
    case 'add-facture':
      normalRoute();
      break;
    default:
      next();
      break;
  }

  // if (requiresAuth && !user) {
  //   next('login');
  // } else {
  //   if (isVerified && to.name != 'verify') {
  //     next();
  //   } else if (!isVerified && to.name === 'verify') {
  //     next();
  //   } else if (isVerified && to.name === 'verify') {
  //     next('')
  //   } else {
  //     next('verify');
  //   }
  // }
})

export default router
