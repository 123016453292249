var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button",class:{
    primary: _vm.isPrimary,
    active: _vm.active,
    disabled: !_vm.clickable,
    greyed: _vm.disabled,
    noPadding: _vm.noPadding,
    isLight: _vm.isLight,
    loading: _vm.loading,
    isSmall: _vm.isSmall,
    isSuccess: _vm.isSuccess,
    isDanger: _vm.isDanger,
  },on:{"click":_vm.newOnClick}},[(_vm.badgeAmount > 0)?_c('div',{staticClass:"badge"},[_c('p',[_vm._v(_vm._s(_vm.badgeAmount))])]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"w"},[(_vm.swapIcon)?_c('div',{staticClass:"w"},[(_vm.icon != null)?_c('div',{staticClass:"icon",class:{ flip: _vm.flip }},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),(_vm.label.length > 0)?_c('h3',{staticClass:"label",class:{ hasLabel: _vm.hasLabel }},[_vm._v(" "+_vm._s(_vm.label == "NONE" ? "" : _vm.label)+" ")]):_vm._e()]):_c('div',{staticClass:"w"},[(_vm.label.length > 0)?_c('h3',{staticClass:"label",class:{ hasLabel: _vm.hasLabel }},[_vm._v(" "+_vm._s(_vm.label == "NONE" ? "" : _vm.label)+" ")]):_vm._e(),(_vm.icon != null)?_c('div',{staticClass:"icon",class:{ flip: _vm.flip }},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()])]):_c('div',{staticClass:"loader"},[_c('LoaderComp')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }