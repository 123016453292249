var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button",class:{
    primary: _vm.isPrimary,
    active: _vm.active,
    disabled: !_vm.clickable,
    greyed: _vm.disabled,
    noPadding: _vm.noPadding,
    isLight: _vm.isLight,
  },on:{"click":_vm.newOnClick}},[_c('div',{staticClass:"w"},[_c('div',{staticClass:"icon"},[_c('menu-icon',{attrs:{"active":_vm.flip}})],1),(_vm.label.length > 0)?_c('h3',{staticClass:"label",class:{ hasLabel: _vm.hasLabel }},[_vm._v(" "+_vm._s(_vm.label == "NONE" ? "" : _vm.label)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }