<template>
  <div
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    class="menu"
    :class="{ fs: fs }"
  >
    <div class="top">
      <!-- <transition name="fade" mode="out-in">
        <h1 v-if="!fs" key="timsheet" class="logo">Timesheet</h1>
        <h1 v-else key="ts" class="logo">Ts</h1>
      </transition> -->
      <transition name="fade" mode="out-in">
        <div v-if="!fs" key="full" class="fullLogo">
          <img src="@/assets/logo_white.png" alt="logo" class="smallLogo" />
          <div class="textLogo">
            <h1 class="logo">Timesheet</h1>
            <p @click="changeAccount" class="accountName">{{ accountName.toUpperCase() }}</p>
          </div>
        </div>
        <div v-else key="small" class="fullLogo">
          <img src="@/assets/logo_white.png" alt="logo" class="smallLogo" />
        </div>
      </transition>
    </div>
    <div class="links-w">
      <div :key="0" class="links">
        <CustomButton
          v-for="link in links"
          :key="`${link.title}${Math.random()}`"
          :label="link.title"
          :icon="link.icon"
          :has-label="!fs"
          :onclick="() => navTo(link.route)"
          :is-primary="false"
          :is-left="true"
          :active="isActive(link.name)"
        />
      </div>
      <!-- <div :key="1" v-else class="links">
        <CustomButton
          v-for="link in links"
          :key="link.title"
          :icon="link.icon"
          :onclick="() => navTo(link.route)"
          :is-primary="false"
          :active="isActive(link.name)"
        />
      </div> -->
    </div>
    <div class="bottom">
      <div class="ww">
        <!-- <MenuToggleButton
          :key="0"
          :flip="locked"
          :label="locked ? 'Débloquer' : 'Garder ouvert'"
          :has-label="!fs"
          :onclick="toggleFs"
          :is-left="true"
          :is-primary="true"
        /> -->
        <MenuToggleButton
          :key="0"
          :flip="!fs"
          :label="fs ? 'Ouvrir' : 'Fermer'"
          :has-label="!fs"
          :onclick="toggleFs"
          :is-left="true"
          :is-primary="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import MenuToggleButton from "@/components/MenuToggleButton.vue";
export default {
  components: { CustomButton, MenuToggleButton },
  data: () => ({
    links: [
      {
        title: "dashboard",
        name: "dashboard",
        route: "/",
        iconPath: "",
        icon: "dashboard",
      },
      {
        title: "utilisateurs",
        name: "users",
        route: "/users",
        iconPath: "",
        icon: "group",
      },
      {
        title: "clients",
        route: "/clients",
        name: "clients",
        iconPath: "",
        icon: "apartment",
      },
      {
        title: "missions",
        name: "missions",
        route: "/missions",
        iconPath: "",
        icon: "verified",
      },
      {
        title: "factures",
        name: "factures",
        route: "/factures",
        iconPath: "",
        icon: "receipt_long",
      },
      {
        title: "sheets",
        name: "sheets",
        route: "/sheets",
        iconPath: "",
        icon: "receipt",
      },
    ],
    locked: false,
  }),
  computed: {
    computedAccountUID() {
      return this.$cookies.get("accountuid");
    },
    accountName() {
      return this.$cookies.get("accountname");
    },
    currentRouteName() {
      return this.$route.name;
    },
    fs() {
      return this.$store.state.isFullscreen;
    },
  },
  methods: {
    changeAccount() {
      this.$router.push({
        name: "select-account",
      });
    },
    mouseEnter() {
      // if (!this.locked) {
      //   this.$store.commit("fsOff");
      // }
    },
    mouseLeave() {
      // if (!this.locked) {
      //   this.$store.commit("fsOne");
      // }
    },
    // toggleFs() {
    //   if (this.locked) {
    //     this.locked = false;
    //   } else {
    //     this.locked = true;
    //     this.$store.commit("fsOff");
    //   }
    // },
    // function that opens or closes the menu
    toggleFs() {
      this.$store.commit(this.fs ? "fsOff" : "fsOne");
    },
    isActive(name) {
      // console.log(`${this.currentRouteName} : ${name}`);
      return (
        this.currentRouteName == name ||
        this.currentRouteName == name.substring(0, this.currentRouteName.length)
      );
    },
    navTo(name) {
      this.$router.push(name);
    },
    logout() {
      console.log("logouuut");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: calc(100% - 20px);
  justify-content: start !important;
  transition: width 700ms;
  background-color: $p-900;
  &.fs {
    width: 130px;
  }
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    border-bottom-right-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $p-300;
    .fullLogo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .textLogo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
      h1.logo {
        margin: 0;
      }
      p.accountName {
        position: relative;
        right: -9px;
        cursor: pointer;
        margin: 0;
        text-align: right;
        font-size: 11px;
        opacity: 0.4;
        font-weight: 700;
        color: white;
        transition: all 100ms;
        &::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 4px solid white;
          margin-left: 5px;
          transition: all 100ms;
          opacity: 0;
        }
        &:hover {
          opacity: 1;
          transform: scale(1.03);
          right: 0px;
          &::after {
            opacity: 1;
          }
        }
      }
      .smallLogo {
        height: 32px;
      }
    }
    h1.logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-weight: 700;
      color: white;
    }
  }
  .links-w {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    background-color: $p-300;
    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $p-900;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 20px;
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    background-color: $p-900;
    .ww {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-top-right-radius: 45px;
      background-color: $p-300;
      padding: 30px;
      // padding-top: ;
      padding-bottom: 0px;
      justify-content: end !important;
    }
  }
}
</style>
