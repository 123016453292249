<template>
  <div class="wrapper">
    <p v-if="labelOff.length > 0" class="label">{{ labelOff }}</p>
    <div
      class="switch"
      :class="{ on: on, hover: hover, loading: loading, isSmall: isSmall }"
      @click="onclick"
      @mouseenter="hoverStart"
      @mouseleave="hoverEnd"
    >
      <div class="val" />
    </div>
    <p v-if="labelOn.length > 0" class="label">{{ labelOn }}</p>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    on: {
      type: Boolean,
      default: false,
    },
    onclick: {
      type: Function,
      default: () => {},
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    labelOff: {
      type: String,
      default: "",
    },
    labelOn: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    hoverStart() {
      this.hover = true;
    },
    hoverEnd() {
      this.hover = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.label {
  margin: 0px;
  font-size: $font-size-1;
  margin-right: 10px;
  margin-left: 0px;

  &:last-child {
    margin-left: 10px;
    margin-right: 0px;
  }
}
.switch {
  box-sizing: border-box;
  transition: background 100ms, width 400ms, margin 400ms;
  cursor: pointer;
  height: 36px;
  width: 66px;
  padding: 6px;
  border-radius: 35px;
  background-color: $input-bg;
  &.loading {
    width: 36px;
    margin-right: 15px;
    background-color: $input-bg !important;
    .val {
      margin: 0px !important;
      background-color: $p-300 !important;
    }
  }
  .val {
    transition: all 400ms;
    height: 24px;
    width: 24px;
    border-radius: 30px;
    background-color: $p-300;
  }
  &.hover {
    background-color: rgba($color: $p-300, $alpha: 0.2);
    .val {
      scale: 0.9;
    }
  }

  &.on {
    background-color: $p-300;
    // padding-left: 35px;
    .val {
      margin-left: 30px;
      background-color: $p-900;
    }

    &.hover {
      background-color: rgba($color: $p-300, $alpha: 0.8);
      .val {
        scale: 0.9;
      }
    }
  }
  &.isSmall {
    height: 24px;
    width: 48px;
    padding: 4px;
    border-radius: 25px;
    .val {
      height: 16px;
      width: 16px;
      border-radius: 20px;
    }
    &.on {
      .val {
        margin-left: 22px;
      }
    }
  }
}
</style>