<template>
  <div v-if="route != 'NotFound'" class="topbar">
    <div class="left">
      <transition name="fade">
        <div v-if="hasBackbtn" class="backButton">
          <CustomButton
            :height="50"
            :width="50"
            :is-primary="true"
            :onclick="goBack"
            icon="arrow_back"
          />
        </div>
      </transition>
      <div class="barItem" :class="{ hasBackButton: hasBackbtn }">
        <div v-for="(item, index) in items" :key="index" class="item">
          <CustomButton
            :clickable="
              items.lenght != 1 && index != items.length - 1
                ? item.clickable
                : false
            "
            :onclick="
              () => {
                goTo(item.value, index);
              }
            "
            :is-primary="false"
            :label="item.text"
          />
          <span
            v-if="index != items.length - 1"
            class="material-symbols-rounded"
          >
            chevron_right
          </span>
        </div>
      </div>
    </div>
    <div class="right">
      <div :key="0" class="inputW" :class="{ show: isPrimaryPage }">
        <div class="iconW">
          <span class="material-symbols-rounded"> search </span>
        </div>
        <input
          v-model="query"
          type="text"
          :placeholder="placeholder"
          class="input search"
        />
      </div>
      <div class="actions" :class="{ hide: actions.length == 0 }">
        <CustomButton
          v-for="(act, index) in actions"
          :key="act.label"
          :height="50"
          :width="btnW"
          :is-primary="true"
          :label="act.label"
          :clickable="!act.dis"
          :disabled="act.dis"
          :icon="act.icon ? act.icon : 'add'"
          :onclick="act.func"
          :style="{ marginLeft: index == 0 ? '20px' : '0px' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "./CustomButton.vue";
import { db, auth } from "@/firebase";
export default {
  components: { CustomButton },
  data: () => ({
    btnW: 230,
    query: "",
    users: [],
    missions: [],
    clients: [],
    invoices: [],
    credits: [],
  }),
  computed: {
    computedAccountUID() {
      return this.$cookies.get("accountuid");
    },
    hasBackbtn() {
      const route = this.route;
      switch (route) {
        case "dashboard":
          return false;
        case "clients":
          return false;
        case "users":
          return false;
        case "missions":
          return false;
        case "sheets":
          return false;
        case "factures":
          return false;

        default:
          return true;
      }
    },
    actions() {
      switch (this.route) {
        case "dashboard":
          return [
            {
              label: "Se Deconnecter",
              icon: "logout",
              func: () => this.logout(),
            },
          ];
        case "sheets":
          return [
            {
              label: "Export CSV",
              icon: "download",
              func: () => this.exportData(),
            },
            {
              label: "Nouvelle entrée",
              func: () => this.newSheet(),
            },
          ];
        case "clients":
          return [
            {
              label: "Nouveau client",
              icon: "apartment",
              func: () => this.newClient(),
            },
          ];
        case "missions":
          return [
            {
              label: "Nouvelle mission",
              icon: "verified",
              func: () => this.newMission("missions"),
            },
          ];
        case "users":
          return [
            {
              label: "Inviter",
              icon: "person_add",
              func: () => this.inviteUser(),
            },
          ];
        case "mission":
          return [
            {
              label: "Nouvelle facture",
              icon: "receipt_long",
              func: () => this.newInvoice("mission"),
            },
            {
              label: "Modifier",
              icon: "edit",
              func: () => this.editMission("mission"),
            },
          ];
        case "factures":
          return [
            {
              label: "Nouvelle facture",
              icon: "receipt_long",
              func: () => this.newInvoice(),
            },
          ];
        case "client":
          return [
            {
              label: "Nouvelle mission",
              icon: "verified",
              func: () => this.newMission("client"),
            },
            {
              label: "Nouvelle facture",
              icon: "receipt_long",
              func: () => this.newInvoice("client"),
            },
          ];
        case "credits":
          return [
            {
              label: "Nouvel Avoir",
              icon: "add",
              dis: this.canAddCredit(),
              func: () => this.newCredit(),
            },
          ];
        default:
          return [];
      }
    },
    action() {
      switch (this.route) {
        case "dashboard":
          return false;
        case "client":
          return false;
        case "user":
          return false;
        case "mission":
          return true;
        case "sheet":
          return false;
        case "facture":
          return false;

        default:
          return true;
      }
    },
    route() {
      console.log(this.$route.name);
      return this.$route.name;
    },
    isPrimaryPage() {
      switch (this.route) {
        case "dashboard":
          return false;
        case "clients":
          return true;
        case "users":
          return true;
        case "missions":
          return true;
        case "sheets":
          return true;
        case "factures":
          return true;

        default:
          return false;
      }
      // return this.items.length == 1 && this.route != "dashboard";
    },
    placeholder() {
      switch (this.items[0].text) {
        case "clients":
          return "Nom, temps, honoraires, etc..";
        case "missions":
          return "Nom, client, temps, etc..";
        case "users":
          return "Nom, temps, honoraires, etc..";
        case "sheets":
          return "Utilisateur, client, status, etc..";
        case "factures":
          return "Numero, date, montant, etc..";
        default:
          return "";
      }
    },
    items() {
      const arr = this.path.substring(1, this.path.length).split("/");
      const obj = arr.map((e, index) => {
        return {
          text: e,
          value: this.getClickPath(`${arr.slice(0, index + 1).join("/")}`),
          clickable: this.isClickable(e),
        };
      });
      if (this.route == "dashboard") {
        return [
          {
            text: `Dashboard`,
          },
        ];
      }
      if (this.route == "user") {
        if (this.users.length > 0) {
          const id = obj[1].text;
          const username =
            this.users[this.users.findIndex((user) => user.uid == id)].fullName;
          obj[1].text = username;
        }
      }
      if (this.route == "mission") {
        if (this.missions.length > 0) {
          const id = obj[1].text;
          const missionName =
            this.missions[
              this.missions.findIndex((mission) => mission.uid == id)
            ].title;
          obj[1].text = missionName;
        }
      }
      if (this.route == "edit-mission") {
        if (this.missions.length > 0) {
          const id = obj[1].text;
          const missionName =
            this.missions[
              this.missions.findIndex((mission) => mission.uid == id)
            ].title;
          obj[1].text = missionName;
          obj[1].clickable = true;
        }
      }
      if (this.route == "clientMission") {
        if (this.missions.length > 0 && this.clients.length > 0) {
          const missionId = obj[2].text;
          const missionName =
            this.missions[
              this.missions.findIndex((mission) => mission.uid == missionId)
            ].title;
          const clientId = obj[1].text;
          const clientName =
            this.clients[
              this.clients.findIndex((client) => client.uid == clientId)
            ].name;
          obj[1].text = clientName;
          obj[2].text = missionName;
          obj[1].clickable = true;
        }
      }
      if (this.route == "client") {
        if (this.clients.length > 0) {
          const id = obj[1].text;
          console.log("Client ID topbar : " + id);
          const clientName =
            this.clients[this.clients.findIndex((client) => client.uid == id)]
              .name;
          obj[1].text = clientName;
        }
      }
      if (this.route == "facture") {
        if (this.invoices.length > 0) {
          const id = obj[1].text;
          const invoiceNumber =
            this.invoices[
              this.invoices.findIndex((invoice) => invoice.id == id)
            ].invoiceId;
          obj[1].text = invoiceNumber;
          obj[1].clickable = true;
        }
      }
      if (this.route == "new-credit") {
        if (this.invoices.length > 0) {
          const id = obj[1].text;
          const invoiceNumber =
            this.invoices[
              this.invoices.findIndex((invoice) => invoice.id == id)
            ].invoiceId;
          obj[1].text = invoiceNumber;
          obj[1].clickable = true;
          obj[2].clickable = true;
          obj[2].text = "Avoirs";
          obj[3].clickable = true;
          obj[3].text = "Nouvel Avoir";
        }
      }
      if (this.route == "credits") {
        if (this.invoices.length > 0) {
          const id = obj[1].text;
          const invoiceNumber =
            this.invoices[
              this.invoices.findIndex((invoice) => invoice.id == id)
            ].invoiceId;
          obj[1].text = invoiceNumber;
          obj[1].clickable = true;
          obj[2].text = "Avoirs";
        }
      }
      if (this.route == "credit") {
        if (this.invoices.length > 0 && this.credits.length > 0) {
          const iid = obj[1].text;
          const cid = obj[3].text;
          const invoiceNumber =
            this.invoices[
              this.invoices.findIndex((invoice) => invoice.id == iid)
            ].invoiceId;
          const creditNumber =
            this.credits[this.credits.findIndex((credit) => credit.id == cid)]
              .creditId;
          obj[1].text = invoiceNumber;
          obj[3].text = creditNumber;
          obj[1].clickable = true;
          obj[2].clickable = true;
          obj[2].text = "Avoirs";
        }
      }
      if (this.route == "add-facture-client") {
        if (this.clients.length > 0) {
          const id = obj[1].text;
          const clientName =
            this.clients[this.clients.findIndex((client) => client.id == id)]
              .name;
          obj[1].text = clientName;
          obj[1].clickable = true;
          obj[2].text = "Nouvelle Facture";
        }
      }
      if (this.route == "add-facture-mission") {
        if (this.clients.length > 0) {
          const id = obj[1].text;
          const missionName =
            this.missions[
              this.missions.findIndex((mission) => mission.id == id)
            ].title;
          obj[1].text = missionName;
          obj[1].clickable = true;
          obj[2].text = "Nouvelle Facture";
        }
      }
      if (this.route == "add-facture") {
        obj[1].clickable = false;
        obj[1].text = "Nouvelle Facture";
      }
      if (this.route == "nouvelle-mission-client") {
        if (this.clients.length > 0) {
          const id = obj[1].text;
          const clientName =
            this.clients[this.clients.findIndex((client) => client.id == id)]
              .name;
          obj[1].text = clientName;
          obj[1].clickable = true;
          obj[2].text = "Nouvelle Mission";
        }
      }
      if (this.route == "nouvelle-mission") {
        obj[1].clickable = false;
        obj[1].text = "Nouvelle Mission";
      }
      if (this.route == "invite-user") {
        obj[1].clickable = false;
        obj[1].text = "Inviter";
      }

      return obj;
    },
    path() {
      console.log(this.$route.path);
      return this.$route.path;
    },
    params() {
      console.log(this.path);
      console.log(this.$route.params);
      return {};
    },
  },
  watch: {
    query() {
      this.$store.commit("setQuery", this.query);
    },
  },
  methods: {
    canAddCredit() {
      console.log(this.$route.params);
      const invoiceId = this.$route.params.invoiceId;
      const invoice = this.invoices.find((invoice) => invoice.uid == invoiceId);
      const credits = this.credits.filter(
        (credit) => credit.invoice == invoiceId
      );
      const totalCredits = credits.reduce((a, b) => a + Math.abs(b.amount), 0);
      console.log(invoice.amount);
      console.log(totalCredits);
      return invoice.amount == totalCredits || invoice.amount < totalCredits;
    },
    // callback() {
    //   const route = this.route;
    //   switch (route) {
    //     case "clients":
    //       console.log("nouveau client");
    //       this.newClient();
    //       break;
    //     case "missions":
    //       console.log("nouvelle mission");
    //       break;
    //     case "factures":
    //       console.log("nouvelle facture");
    //       break;
    //     default:
    //       console.log("default topbar callback");
    //       break;
    //   }
    // },
    async logout() {
      await auth.signOut();
      this.$cookies.remove("accountname");
      this.$cookies.remove("accountuid");
      // this.$cookies.remove('accountname');
      // this.$cookies.remove("accountuid");
      // this.$store.commit('setAccountUid', '');
      this.$router.push({
        name: "login",
      });
    },
    getClickPath(path) {
      console.log({ path });
      return path;
    },
    inviteUser() {
      this.$router.push({
        name: "invite-user",
      });
    },
    newClient(from) {
      const f = from ?? "";
      if (f.length > 0) {
        this.$router.push({
          name: "nouveau-client",
          params: {
            from: null,
          },
        });
      } else {
        this.$router.push({
          name: "nouveau-client",
          params: {
            from: f,
          },
        });
      }
    },
    newSheet(from) {
      const f = from ?? "";
      if (f.length > 0) {
        this.$router.push({
          name: "add-sheet",
          params: {
            from: null,
          },
        });
      } else {
        this.$router.push({
          name: "add-sheet",
          params: {
            from: f,
          },
        });
      }
    },
    exportData(from) {
      const f = from ?? "";
      if (f.length > 0) {
        this.$store.commit("setCurrentModalAndShow", 'EXPORT_CSV');
      } else {
        this.$store.commit("setCurrentModalAndShow", 'EXPORT_CSV');
      }
    },
    newInvoice(from) {
      const f = from ?? "";
      if (f == "client") {
        let id = this.$route.params.clientId;
        this.$router.push({
          name: "add-facture-client",
          params: {
            from: "client",
            fromClient: id ?? "",
            fromMission: "",
          },
        });
      } else if (f == "mission") {
        let missionId = this.$route.params.missionId;
        this.$router.push({
          name: "add-facture-mission",
          params: {
            from: f,
            fromClient: "",
            fromMission: missionId ?? "",
          },
        });
      } else {
        this.$router.push({
          name: "add-facture",
          params: {
            from: f,
            fromClient: "",
            fromMission: "",
          },
        });
      }
    },
    newCredit() {
      let id = this.$route.params.invoiceId;
      this.$router.push({
        name: "new-credit",
        params: {
          invoiceId: id ?? "",
        },
      });
    },
    newMission(from) {
      const f = from ?? "";
      if (f == "missions") {
        this.$router.push({
          name: "nouvelle-mission",
          params: {
            from: null,
          },
        });
      } else {
        this.$router.push({
          name: "nouvelle-mission-client",
          params: {
            from: f,
          },
        });
      }
    },
    editMission(from) {
      let id = this.$route.params.missionId;
      const f = from ?? "";
      if (f == "missions") {
        this.$router.push({
          name: "edit-mission",
          params: {
            from: null,
            missionId: id,
          },
        });
      } else {
        this.$router.push({
          name: "edit-mission",
          params: {
            from: f,
            missionId: id,
          },
        });
      }
    },
    // newInvoice() {
    //   console.log('NEW INVOICE');
    //   this.$store.commit("showNewInvoice");
    // },
    goBack() {
      this.$router.go(-1);
    },
    goTo(path, index) {
      console.log({ path });
      console.log({ index });
      this.$router.push({ path: `/${path}` });
      // if (this.route == "clientMission" && index == 1) {
      //   this.$router.push({
      //     name: "client",
      //     params: {
      //       clientId: name,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name,
      //   });
      // }
    },
    isClickable(e) {
      console.log("is clickable", e);
      switch (e) {
        case "clients":
          return true;
        case "missions":
          return true;
        case "users":
          return true;
        case "sheets":
          return true;
        case "factures":
          return true;
        default:
          return false;
      }
    },
  },
  firestore() {
    return {
      users: db
        .collection("users")
        .where("accounts", "array-contains", this.computedAccountUID),
      missions: db
        .collection("missions")
        .where("account", "==", this.computedAccountUID),
      clients: db
        .collection("clients")
        .where("account", "==", this.computedAccountUID),
      invoices: db
        .collection("invoices")
        .where("account", "==", this.computedAccountUID),
      credits: db
        .collection("credits")
        .where("account", "==", this.computedAccountUID),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.topbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: $g-50;
  // background-color: $p-800;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0px;
  padding-left: 40px;
  padding-right: 30px;
  width: 100%;
  min-height: 70px;
  border-radius: 25px;
  // align-items: flex-start !important;
  margin-bottom: 20px;
  gap: 50px;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 0px;
    flex-direction: row !important;
    justify-content: end !important;
    align-items: center;
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-direction: row !important;
      justify-content: flex-end !important;
      align-items: center;
      gap: 20px;
      overflow: hidden;
      transition: all 300ms;
      border-radius: 17px;
      margin-left: 20px;
      width: v-bind(
        "`${actions.length > 0 ? ((btnW * actions.length)) : 0}px`"
      );
      &.hide {
        opacity: 0;
        width: 0px;
      }
    }
    .trans {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-direction: row !important;
      // gap: 20px;
    }
    .inputW {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 0px;
      opacity: 0;
      max-width: 600px;
      transition: all 600ms;
      transition-timing-function: $curveOut;
      border-radius: 17px;
      overflow: hidden;
      &.show {
        opacity: 1;
        width: 500px;
        right: -20px;
        &.hasActions {
          right: 0px;
        }
      }

      .iconW {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        left: 10px;
        width: 50px;
        span {
          color: $g-200;
        }
      }
      input.input {
        all: unset;
        box-sizing: border-box;
        text-align: left;
        background-color: $input-bg;
        padding: 14px 20px;
        padding-left: 70px;
        border-radius: 17px;
        width: 100%;
        height: 50px;
        min-width: 500px;
        font-weight: 700;
        &::placeholder {
          color: $g-200;
        }
      }
    }
  }
  .backButton {
    z-index: 9999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .barItem {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center;
    transition: all 600ms;
    transition-timing-function: $curveOut;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row !important;
    }
    h2 {
      color: black;
      text-transform: capitalize;
      margin: 0px;
      &.sep {
        margin-left: 30px;
        margin-right: 30px;
      }
    }
    &.hasBackButton {
      transform: translateX(80px);
    }
  }
}
</style>