import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentSearchQuery: '',
    isFullscreen: false,
    newClient: false,
    newInvoice: false,
    accountUid: '',
    appLoaded: false,
    accountName: '',
    currentModal: '',
    showCurrentModal: false,
    // currentClient: '',
  },
  getters: {
    getAccountUid: state => {
      return state.accountUid;
    }
  },
  mutations: {
    // setCurrentClient(state, clientId) {
    //   state.currentClient = clientId;
    // },
    // MODALS
    setCurrentModal(state, modalName) {
      state.currentModal = modalName;
    },
    setCurrentModalAndShow(state, modalName) {
      state.currentModal = modalName;
      state.showCurrentModal = true;
    },
    showCurrentModal(state) {
      state.showCurrentModal = true;
    },
    hideCurrentModal(state) {
      state.showCurrentModal = false;
    },
    // END MODALS
    setAccountName(state, string) {
      state.accountName = string;
    },
    setAppLoaded(state) {
      console.log('app loaded');
      state.appLoaded = true;
    },
    setAccountUid(state, string) {
      console.log('acccount ID updated to ' + string);
      state.accountUid = string;
    },
    setQuery(state, string) {
      state.currentSearchQuery = string;
    },
    resetQuery(state) {
      state.currentSearchQuery = '';
    },
    fullscreen(state) {
      state.isFullscreen = true;
    },
    quitFullscreen(state) {
      state.isFullscreen = false;
    },
    toggleFs(state) {
      state.isFullscreen = !state.isFullscreen;
    },
    fsOne(state) {
      state.isFullscreen = true;
    },
    fsOff(state) {
      state.isFullscreen = false;
    },
    showNewClient(state) {
      state.newClient = true;
    },
    closeNewClient(state) {
      state.newClient = false;
    },
    showNewInvoice(state) {
      state.newInvoice = true;
    },
    closeNewInvoice(state) {
      state.newInvoice = false;
    }
  },
  actions: {
  },
  modules: {
  }
});

export default store;
