<template>
  <div id="app" :class="{ showApp: showApp }">
    <transition name="fade" mode="out-in">
      <div
        key="login"
        v-if="isLogin || isVerif || isAccept || isSelectAccount"
        class="login-wrapper"
      >
        <div class="login-view-content">
          <router-view />
        </div>
      </div>
      <div key="app" v-else class="view-wrapper-wrapper">
        <SideMenu id="menu" />
        <div class="view-wrapper" :class="{ fs: fs }">
          <div class="view-content">
            <div class="whitegrad"></div>
            <TopBar />
            <transition name="fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="appLoading" class="loading-wrapper">
        <div class="loading-view-content">
          <div class="logo">
            <img src="@/assets/logo.png" />
            <!-- <EllipsisLoader :color="'#0019ff'" /> -->
            <LoaderComp is-primary />
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <component v-if="showCurrentModal" v-bind:is="currentModal"></component>
    </transition>
  </div>
</template>

<script>
import SideMenu from "./components/SideMenu.vue";
import TopBar from "./components/TopBar.vue";
import LoaderComp from "./components/LoaderComp.vue";
import ExportSheetsVue from "./popups/ExportSheets.vue";

export default {
  components: {
    SideMenu,
    TopBar,
    LoaderComp,
  },
  data() {
    return {
      showApp: false,
      appLoading: true,
      showModel: false,
    };
  },
  computed: {
    currentModal() {
      const modalName = this.$store.state.currentModal;
      switch (modalName) {
        case "EXPORT_CSV":
          return ExportSheetsVue;
        default:
          return null;
      }
    },
    showCurrentModal() {
      return this.$store.state.showCurrentModal;
    },
    appLoadedStoreValue() {
      return this.$store.state.appLoaded;
    },
    computedAccountUID() {
      return this.$cookies.get("accountuid");
    },
    fs() {
      return this.$store.state.isFullscreen;
    },
    isLogin() {
      const isLogin = this.$route.name == "login";
      console.log({ isLogin });
      return isLogin;
    },
    isSelectAccount() {
      const isSelectAccount = this.$route.name == "select-account";
      console.log({ isSelectAccount });
      return isSelectAccount;
    },
    isVerif() {
      const isVerif = this.$route.name == "verify";
      console.log({ isVerif });
      return isVerif;
    },
    isAccept() {
      const isAccept = this.$route.name == "accept";
      console.log({ isAccept });
      return isAccept;
    },
  },
  watch: {
    appLoadedStoreValue() {
      if (this.appLoadedStoreValue) {
        setTimeout(() => {
          this.appLoading = false;
        }, 400);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.showApp = true;
    }, 10);
    // setTimeout(() => {
    //   this.appLoading = false;
    // }, 100);
  },
};
</script>


<style lang="scss">
@import "./index.scss";

// p,
// span,
// div,
// a,
// li,
// ul,
// ol,
// input,
// textarea,
// button {
//   font-size: $font-size-1;
// }

// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-size: $font-size-2;
// }

// h1 {
//   font-size: $font-size-3;
// }

.loading-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  width: calc(100%);
  height: 100%;
  padding: 20px;
  background-color: $p-300;
  .loading-view-content {
    background-color: $p-900;
    border-radius: 45px;
    // padding: 30px;
    padding-top: 30px;
    width: 100%;
    height: 100%;
    // gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start !important;
    align-items: flex-start !important;
    .logo {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 60px;
      img {
        width: 100px;
      }
    }
  }
}

html,
body {
  padding: 0px;
  margin: 0px;
  background-color: $p-300;
}

.simplebarclass {
  min-width: 100%;
  height: 100%;
}

.simplebar-content {
  min-width: 100%;
}

.simplebar-scroll-content {
  min-width: 100%;
}

.simplebar-content-wrapper {
  min-width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.fade-list-item {
  display: inline-block;
  margin-right: 10px;
}
.fade-list-enter-active,
.fade-list-leave-active {
  transition: all 400ms;
}
.fade-list-enter, .fade-list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  // transform: translateY(30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s !important;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-long-enter-active,
.fade-long-leave-active {
  transition: opacity 0.7s !important;
}
.fade-long-enter,
.fade-long-leave-to {
  opacity: 0;
}

.fadeslide-enter-active,
.fadeslide-leave-active {
  transition: all 600ms ease;
  transition-timing-function: cubic-bezier(0.94, 0, 0.05, 1);
}
.fadeslide-enter
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100%);
}
.fadeslide-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.fadeslideback-enter-active,
.fadeslideback-leave-active {
  transition: all 600ms ease;
  transition-timing-function: cubic-bezier(0.94, 0, 0.05, 1);
}
.fadeslideback-enter
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-100%);
}
.fadeslideback-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slideFromBottomIn-enter-active,
.slideFromBottomIn-leave-active {
  transition: all 600ms ease;
  transition-timing-function: cubic-bezier(0.94, 0, 0.05, 1);
}
.slideFromBottomIn-enter
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(100%);
}
.slideFromBottomIn-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

.slideFromBottomOut-enter-active,
.slideFromBottomOut-leave-active {
  transition: all 600ms ease;
  transition-timing-function: cubic-bezier(0.94, 0, 0.05, 1);
}
.slideFromBottomOut-enter
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-100%);
}
.slideFromBottomOut-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.material-symbols-rounded {
  font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 200, "opsz" 40;
}

* {
  box-sizing: border-box;
}

.ProseMirror {
  outline: none !important;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: $g-200;
  font-weight: 700;
  pointer-events: none;
  height: 0;
}

.my-title-bubble {
  font-family: "Urbanist", sans-serif;
}

// .button {
//   height: 60px;
//   width: 100%;
//   border-radius: 15px;
//   cursor: pointer;
//   transition: all 100ms;
//   .w {
//     gap: 20px;
//     width: 70%;
//     justify-content: flex-start !important;
//     flex-direction: row !important;
//     .icon {
//       height: 20px;
//       width: 20px;
//     }
//     h3 {
//       text-transform: capitalize;
//       color: $g-900;
//     }
//   }
//   &:hover {
//     background-color: $p-800;
//   }
//   &.active {
//     background-color: $p-300;
//     box-shadow: $btn-shadow;
//     transform: scale(1.02);
//     .icon {
//       height: 20px;
//       width: 20px;
//       color: white;
//       opacity: 1;
//     }
//     h3 {
//       text-transform: capitalize;
//       font-weight: 900;
//       color: white;
//     }
//   }
// }

span.nodata {
  color: $p-200;
  opacity: 0.3;
  font-size: $font-size-1;
}

#app {
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: row;

  height: 100vh;
  width: 100vw;

  opacity: 0;

  transition: opacity 200ms;

  &.showApp {
    opacity: 1;
  }

  .login-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    height: 100%;
    padding: 20px;
    background-color: $p-300;
    .login-view-content {
      background-color: $p-900;
      border-radius: 45px;
      // padding: 30px;
      padding-top: 30px;
      width: 100%;
      height: 100%;
      // gap: 20px;
      display: flex;
      flex-direction: column;
      justify-content: start !important;
      align-items: flex-start !important;
    }
  }

  .view-wrapper-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .view-wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    background-color: $p-300;
    transition: width 700ms;
    &.fs {
      width: calc(100% - 130px);
    }
    &.login {
      width: 100%;
      padding-left: 20px;
      .whitegrad {
        opacity: 0;
      }
    }
    .view-content {
      position: relative;
      overflow: hidden;
      background-color: $p-900;
      border-radius: 45px;
      // padding: 30px;
      padding-top: 30px;
      width: 100%;
      height: 100%;
      // gap: 20px;
      display: flex;
      flex-direction: column;
      justify-content: start !important;
      align-items: flex-start !important;
      .whitegrad {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 30px;
        background-color: green;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 32%,
          rgba(255, 255, 255, 0) 100%
        );

        z-index: 99999;
      }
    }
  }

  input#datepickerNewID {
    font-weight: 700 !important;
  }

  .dropdown {
    input {
      all: unset;
      box-sizing: border-box;
      text-align: left;
      background-color: $input-bg;
      padding: 14px 20px;
      border-radius: 17px;
      width: 100%;
      height: 60px;
      // min-width: 500px;
      font-weight: 700;
      &::placeholder {
        color: $g-200;
      }
      &.icon {
        padding-left: 70px;
      }
    }
  }

  div {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // &.row {
    //   flex-direction: row;
    // }
  }
}
</style>
