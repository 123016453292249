<script>
import { db } from "../firebase";
import CustomButton from "@/components/CustomButton.vue";
import SwitchComp from "@/components/SwitchComp.vue";
import { invoiceReverseStatus, sheetsReverseStatus } from "@/utils/enums";
import { formatDate } from "@/utils/helpers";

export default {
  components: { CustomButton, SwitchComp },
  data() {
    return {
      // Clients
      clients: [],
      missions: [],
      invoices: [],
      users: [],
      // Export
      exportSheets: true,
      exportInvoices: false,
      // Range
      rangeDrop: false,
      ranges: ["Tout les clients", "Données par client"],
      selectedRange: null,
      // Format
      formatDrop: false,
      formats: ["csv"],
      selectedFormat: null,
      selectedClients: [],
    };
  },
  computed: {
    computedAccountUID() {
      return this.$cookies.get("accountuid");
    },
    isClientSelect() {
      return this.selectedRange === "Données par client";
    },
    listHeight() {
      if (this.isClientSelect) {
        if (this.clients.length >= 4) {
          return `${50 * 4 + 40}px`;
        } else {
          return `${50 * this.clients.length + 20}px`;
        }
      } else {
        return "0px";
      }
    },
    formValid() {
      const isExportDataValid = this.exportSheets || this.exportInvoices;
      const isRangeValid = this.selectedRange !== null;
      const isFormatValid = this.selectedFormat !== null;
      const isClientValid =
        this.selectedClients.length > 0 || !this.isClientSelect;
      return (
        isRangeValid && isFormatValid && isClientValid && isExportDataValid
      );
    },
  },
  methods: {
    // Clients
    isSelected(clientUID) {
      return this.selectedClients.includes(clientUID);
    },
    addClient(client) {
      if (this.isSelected(client.id)) {
        this.selectedClients = this.selectedClients.filter(
          (c) => c !== client.id
        );
      } else {
        this.selectedClients.push(client.id);
      }
    },
    toggleExportSheets() {
      this.exportSheets = !this.exportSheets;
      if (!this.exportSheets) this.exportInvoices = true;
    },
    toggleExportInvoices() {
      this.exportInvoices = !this.exportInvoices;
      if (!this.exportInvoices) this.exportSheets = true;
    },
    // Range
    toggleRange() {
      this.rangeDrop = !this.rangeDrop;
    },
    setRange(range, close) {
      this.selectedRange = range;
      if (close) this.rangeDrop = false;
    },
    // Format
    toggleFormat() {
      this.formatDrop = !this.formatDrop;
    },
    setFormat(format, close) {
      this.selectedFormat = format;
      if (close) this.formatDrop = false;
    },
    async exportData() {
      if (this.exportSheets && !this.exportInvoices) {
        await this.exportSheetsData();
      } else if (!this.exportSheets && this.exportInvoices) {
        await this.exportInvoicesData();
      } else if (this.exportSheets && this.exportInvoices) {
        await this.exportSheetsData();
        await this.exportInvoicesData();
      } else {
        return;
      }
    },
    // Export Invoices
    async exportInvoicesData() {
      let invoicesRef;

      if (this.isClientSelect) {
        invoicesRef = db
          .collection("invoices")
          .where("account", "==", this.computedAccountUID)
          .where("clientId", "in", this.selectedClients);
      } else {
        invoicesRef = db
          .collection("invoices")
          .where("account", "==", this.computedAccountUID);
      }

      if (
        this.clients.length == 0 &&
        this.missions.length == 0 &&
        this.invoices.length == 0 &&
        this.users.length == 0
      ) {
        console.log("no data");
        return;
      }

      const invoicesDocs = await invoicesRef.get();
      const invoices = invoicesDocs.docs.map((doc) => {
        const data = doc.data();
        const invoice = {
          id: doc.id,
          amount: data.amount.toString().replaceAll(".", ","),
          client: this.getClientName(data.clientId).replace(/,/g, ""),
          createdAt: formatDate(data.createdAt.toDate()),
          date: formatDate(data.date.toDate()),
          invoiceId: data.invoiceId,
          invoiceObject: data.invoiceObject
            ? new DOMParser()
                .parseFromString(data.invoiceObject, "text/html")
                .body.textContent.replace(/,/g, "")
                .replace(/(\r\n|\n|\r)/gm, "")
            : "",
          invoiceTitle: data.invoiceTitle
            ? data.invoiceTitle.replace(/,/g, "").replace(/(\r\n|\n|\r)/gm, "")
            : "",
          isOutSys: data.isOutSys,
          mission: this.getMissionName(data.missionId).replace(/,/g, ""),
          status: this.getInvoiceStatus(data.status).replace(/,/g, ""),
          tva: data.tva.toString().replaceAll(".", ","),
          updatedAt: formatDate(data.updatedAt.toDate()),
        };
        return invoice;
      });

      const headers = {
        id: "ID",
        amount: "Montant (HT)",
        client: "Client",
        createdAt: "Ajouté le",
        date: "Date",
        invoiceId: "Numéro de facture",
        invoiceObject: "Objet de la facture",
        invoiceTitle: "Intitulé",
        isOutSys: "Hors système",
        mission: "Mission",
        status: "Statut",
        tva: "TVA",
        updatedAt: "Modifié le",
      };

      this.exportCSVFile(headers, invoices, this.getExportTitle('INVOICE'));
    },
    // Export Sheets
    async exportSheetsData() {
      let sheetsRef;

      if (this.isClientSelect) {
        sheetsRef = db
          .collection("sheets")
          .where("account", "==", this.computedAccountUID)
          .where("clientUid", "in", this.selectedClients);
      } else {
        sheetsRef = db
          .collection("sheets")
          .where("account", "==", this.computedAccountUID);
      }

      if (
        this.clients.length == 0 &&
        this.missions.length == 0 &&
        this.invoices.length == 0 &&
        this.users.length == 0
      ) {
        console.log("no data");
        return;
      }

      const sheetsDocs = await sheetsRef.get();
      const sheets = sheetsDocs.docs.map((doc) => {
        const data = doc.data();
        const sheet = {
          id: doc.id,
          client: this.getClientName(data.clientUid).replace(/,/g, ""),
          createdAt: formatDate(data.createdAt.toDate()),
          date: formatDate(data.date.toDate()),
          details: data.details
            ? data.details.replace(/,/g, "").replace(/(\r\n|\n|\r)/gm, "")
            : "",
          invoiceNumber: this.getInvoiceNumber(data.invoiceId),
          mission: this.getMissionName(data.missionId).replace(/,/g, ""),
          rate: data.rate,
          status: this.getStatus(data.status).replace(/,/g, ""),
          time: data.time,
          user: this.getUsername(data.user).replace(/,/g, ""),
        };
        return sheet;
      });
      console.log({ sheets });
      const headers = {
        id: "ID",
        client: "Client",
        createdAt: "Ajouté le",
        date: "Date",
        details: "Détails",
        invoiceNumber: "Numéro de facture",
        mission: "Mission",
        rate: "Taux",
        status: "Statut",
        time: "Temps",
        user: "Utilisateur",
      };
      this.exportCSVFile(headers, sheets, this.getExportTitle('SHEET'));
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }
      var jsonObject = JSON.stringify(items);
      var csv = this.convertToCSV(jsonObject);
      var exportedFilenmae = fileTitle + ".csv" || "export.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ";";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    getExportTitle(type) {
      const date = new Date();
      const fdate = formatDate(date);
      let prefix;
      if (type === 'SHEET') prefix = 'SHEETS';
      else if (type === 'INVOICE') prefix = 'FACTURES';
      else prefix = 'DATA';
      const title = `export-numeter_${prefix}_${fdate.replaceAll('/', '-')}`;
      return title;
    },
    closeModal() {
      this.$store.commit("hideCurrentModal", false);
    },
    getClientName(clientUID) {
      // console.log('c', clientUID);
      const client = this.clients.find((c) => c.uid === clientUID);
      return client.fullName;
    },
    getInvoiceNumber(invoiceUID) {
      // console.log('i', invoiceUID);
      if (!invoiceUID) return "null";
      const invoice = this.invoices.find((i) => i.uid === invoiceUID);
      return invoice.invoiceId;
    },
    getMissionName(missionUID) {
      console.log("m", missionUID);
      const mission = this.missions.find((m) => m.uid === missionUID);
      return mission.title;
    },
    getStatus(status) {
      return sheetsReverseStatus[status];
    },
    getInvoiceStatus(status) {
      return invoiceReverseStatus[status];
    },
    getUsername(userUID) {
      // console.log('u', userUID);
      const user = this.users.find((u) => u.uid === userUID);
      return user.fullName;
    },
  },
  firestore() {
    return {
      clients: db
        .collection("clients")
        .where("account", "==", this.computedAccountUID),
      missions: db
        .collection("missions")
        .where("account", "==", this.computedAccountUID),
      invoices: db
        .collection("invoices")
        .where("account", "==", this.computedAccountUID),
      users: db
        .collection("users")
        .where("accounts", "array-contains", this.computedAccountUID),
    };
  },
};
</script>

<template>
  <div v-on:click.self="closeModal" class="main">
    <div class="card">
      <CustomButton
        class="closeBtn"
        :has-label="false"
        icon="close"
        :height="60"
        :onclick="closeModal"
      />
      <div class="topCard">
        <p class="title">Export Données</p>
      </div>
      <div class="cardContent">
        <div @click="toggleExportSheets" class="switch-wrapper">
          <h5 class="label">Exporter les Sheets</h5>
          <SwitchComp :on="exportSheets" />
        </div>
        <div @click="toggleExportInvoices" class="switch-wrapper">
          <h5 class="label">Exporter les factures</h5>
          <SwitchComp :on="exportInvoices" />
        </div>
        <div class="inputW">
          <h5 class="label">Segmentation</h5>
          <CustomButton
            :onclick="toggleRange"
            :has-label="true"
            :label="
              selectedRange ? selectedRange.toUpperCase() : 'Selectionnez'
            "
            icon="arrow_drop_down"
            :disabled="false"
            :clickable="true"
            :swap-icon="false"
            :is-primary="true"
          />
          <transition name="fade">
            <div v-if="rangeDrop" class="dropdown">
              <div
                v-for="(range, index) in ranges"
                :key="range"
                class="option"
                @click="setRange(range, true)"
              >
                <p>{{ range.toUpperCase() }}</p>
                <div v-if="index < ranges.length - 1" class="sep"></div>
              </div>
            </div>
          </transition>
        </div>

        <!-- Client List -->
        <div
          :style="`height: ${listHeight}`"
          :class="{ show: isClientSelect }"
          class="inputW clientList"
        >
          <h5 class="label">Clients</h5>

          <div class="list">
            <div
              @click="addClient(client)"
              v-for="client in clients"
              :key="client.id"
              :class="['client', { selected: isSelected(client.id) }]"
            >
              <div class="clientName">{{ client.name }}</div>
              <div :class="['clientCheck']">
                <span class="material-symbols-rounded">check</span>
              </div>
            </div>
          </div>
        </div>
        <div class="sep"></div>
        <div class="inputW">
          <h5 class="label">Format</h5>
          <CustomButton
            :onclick="toggleFormat"
            :has-label="true"
            :label="
              selectedFormat
                ? selectedFormat.toUpperCase()
                : 'Selectionnez un format'
            "
            icon="arrow_drop_down"
            :disabled="false"
            :clickable="true"
            :swap-icon="false"
            :is-primary="true"
          />
          <transition name="fade">
            <div v-if="formatDrop" class="dropdown">
              <div
                v-for="(format, index) in formats"
                :key="format"
                class="option"
                @click="setFormat(format, true)"
              >
                <p>{{ format.toUpperCase() }}</p>
                <div v-if="index < formats.length - 1" class="sep"></div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- export button -->
      <CustomButton
        :has-label="true"
        :label="`Exporter `"
        icon="download"
        :disabled="!formValid"
        :clickable="formValid"
        :swap-icon="false"
        :is-primary="true"
        :onclick="exportData"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../index.scss";
.main {
  z-index: 999999999999;
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  .card {
    position: relative;
    width: 500px;
    padding: 20px;
    padding-top: 10px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    .closeBtn {
      max-width: 60px;
      min-width: 60px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    p.title {
      font-size: $font-size-3;
      margin: 0px;
      font-weight: 700;
    }
    .topCard {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .cardContent {
      border-radius: 12px;
      width: 100%;
      background-color: $g-50;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
.sep {
  height: 5px;
  width: 20%;
  margin: 0px auto;
  background-color: $g-50;
}
.client {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: $g-50;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  &:hover {
    cursor: pointer;
    background-color: $g-50-50;
  }
  &.selected {
    background-color: $g-50-50-50;
    // color: white;
    .clientCheck {
      background-color: transparent;
      span {
        opacity: 1;
      }
    }
  }
  .clientName {
    font-weight: 700;
    text-transform: uppercase;
  }
  .clientCheck {
    width: 18px;
    height: 18px;
    border-radius: 5px;
    background-color: $g-50;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      opacity: 0;
      font-size: 20px;
    }
    &:hover {
      cursor: pointer;
      background-color: $p-300;
      border: 1px solid $p-300;
    }
    &.selected {
      background-color: $p-300;
      border: 1px solid $p-300;
    }
  }
}
.inputW.clientList {
  overflow: hidden;
  transition: all 200ms;
  gap: 10px;
  opacity: 0;
  .list {
    overflow: hidden;
    gap: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 10px;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: initial !important;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      display: initial !important;
      background-color: $g-50-50;
      border-radius: 10px;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: initial !important; /* IE and Edge */
    scrollbar-width: initial !important; /* Firefox */
  }
  &.show {
    opacity: 1;
    .list {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  h5.label {
    margin: 0px;
    // margin-right: 20px;
    font-weight: 600;
    margin-left: 5px;
    font-size: $font-size-2;
  }
}
.switch-wrapper {
  flex-direction: row;
  user-select: none;
  // position: absolute;
  border-radius: 15px;
  position: relative;
  // background-color: red;
  display: flex;
  right: 0px;
  width: calc(100%);
  padding-top: 10px;
  padding-bottom: 10px;
  // flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  transition: all 200ms;
  transition-timing-function: $curve;
  // top: 30px;
  // right: 40px;
  // height: 70px;
  &:hover {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    // right: 10px;
    // width: calc(100% + 20px);
    background-color: $g-50;
  }
  h5.label {
    margin: 0px;
    // margin-right: 20px;
    font-weight: 600;
    margin-left: 5px;
    font-size: $font-size-2;
  }
}
.inputW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  // max-width: 600px;
  align-items: flex-start !important;
  .iconW {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 60px;
    bottom: 0px;
    left: 10px;
    width: 50px;
    span {
      color: $g-200;
    }
  }
  h5.label {
    margin: 0px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-left: 5px;
    font-size: $font-size-2;
  }
  .datepickerW {
    &:hover {
      background-color: $p-800;
    }
    background-color: $input-bg;
    padding: 14px 20px;
    border-radius: 17px;
    width: 100%;
    height: 60px;
    .dpClass {
      color: red;
    }
    &.icon {
      padding-left: 70px;
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 999;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 80px;
    width: 100%;
    box-shadow: $btn-shadow;
    border-radius: 17px;
    padding: 10px;
    overflow: auto;
    max-height: 450px;
    .option {
      transition: all 150ms;
      cursor: pointer;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 50px;
      max-height: 50px;
      border-radius: 11px;
      &.spec {
        background-color: $g-100;
      }
      &:hover {
        background-color: $p-300;
        p {
          color: white;
        }
      }
      p {
        margin: 0px;
        font-weight: 700;
      }
      .notif {
        position: absolute;
        right: 23px;
        top: 23px;
        height: 14px;
        width: 14px;
        border-radius: 10px;
        background-color: $warning;
        box-shadow: $warning-shadow;
      }
      .sep {
        position: absolute;
        bottom: -5px;
        height: 1px;
        width: calc(100% - 60px);
        background-color: $input-bg;
      }
    }
  }

  .dropdownW {
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: $input-bg;
    padding: 14px 20px;
    border-radius: 17px;
    width: 100%;
    height: 60px;
    &:hover {
      background-color: $p-800;
    }
    .value {
      font-weight: 700;
    }
  }

  input.input,
  textarea {
    all: unset;
    box-sizing: border-box;
    text-align: left;
    background-color: $input-bg;
    padding: 14px 20px;
    border-radius: 17px;
    width: 100%;
    height: 60px;
    // min-width: 500px;
    font-weight: 700;
    &::placeholder {
      color: $g-200;
    }
    &.icon {
      padding-left: 70px;
    }
  }
  &.fullHeight {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    // .editor-container {
    //   width: 100%;
    //   padding-left: 18px;
    //   padding-right: 18px;
    //   padding-top: 5px;
    //   padding-bottom: 15px;
    //   height: 100%;
    //   overflow: auto;
    //   display: flex;
    //   justify-content: flex-start;
    //   background-color: $input-bg;
    //   text-align: left;
    //   border-radius: 17px;
    // }
    // background-color: green;
    // flex: 1;
    textarea {
      // height: 100%;
    }
  }
}
</style>