import firebase from "firebase/app";
import 'firebase/messaging';
import 'firebase/firestore';
// import 'firebase/firebase-functions';
import 'firebase/functions';
import 'firebase/auth';
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyCZM7R6jNQQHqKTWxSKsxnq0k01sbmFtLE",
    authDomain: "timesheet-gc.firebaseapp.com",
    projectId: "timesheet-gc",
    storageBucket: "timesheet-gc.appspot.com",
    messagingSenderId: "912447723384",
    appId: "1:912447723384:web:88aeb591a700ee6120da8d",
    measurementId: "G-0SLTM73C68"
};

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};


// TODO: see below to call the firebase "newAccount" function:

// const functions = firebase.functions();
// const callable = await functions.httpsCallable('newAccount')({});


const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const functions = firebase.app().functions('europe-west1');

// check if in development mode
// if (window.location.hostname === "localhost") {
//     console.log("Development mode detected, using emulators");
//     db.useEmulator("localhost", 8081);
//     auth.useEmulator("http://localhost:9099");
//     functions.useEmulator("localhost", 5001);
// }




const fv = firebase.firestore.FieldValue;

export {
    db,
    auth,
    fv,
    firebase,
    functions,
    storage,
};