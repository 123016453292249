<template>
  <div
    class="button"
    :class="{
      primary: isPrimary,
      active: active,
      disabled: !clickable,
      greyed: disabled,
      noPadding: noPadding,
      isLight: isLight,
    }"
    @click="newOnClick"
  >
    <div class="w">
      <div class="icon">
        <!-- <span class="material-symbols-rounded">{{ icon }}</span> -->
        <menu-icon :active="flip" />
      </div>
      <h3 v-if="label.length > 0" class="label" :class="{ hasLabel: hasLabel }">
        {{ label == "NONE" ? "" : label }}
      </h3>
    </div>
  </div>
</template>

<script>
import MenuIcon from "@/components/MenuIcon.vue";
export default {
  components: { MenuIcon },
  props: {
    height: {
      type: Number,
      default: 60,
    },
    width: {
      type: Number,
      default: -1,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    hasLabel: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
    onclick: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: { computedAccountUID() {
      return this.$cookies.get('accountuid');
    },
    wWidth() {
      if (this.isLeft) {
        return "70%";
      } else {
        return "100%";
      }
    },
    align() {
      if (this.isLeft) {
        return "flex-start";
      } else {
        return "center";
      }
    },
    btnHeight() {
      return this.height + "px";
    },
    btnWidth() {
      if (this.width == -1) {
        return "100%";
      } else if (this.width == -2) {
        return "max-content";
      } else {
        return this.width + "px";
      }
    },
  },
  methods: {
    newOnClick() {
      if (this.clickable) {
        this.onclick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  height: v-bind(btnHeight);
  width: v-bind(btnWidth);
  border-radius: 15px;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  transition: all 100ms;
  background-color: $p-900;
  &.isLight {
    background-color: $p-800;
    &:hover {
      &.disabled {
        background-color: $p-800;
      }
      background-color: $p-700;
    }
  }
  &.disabled {
    cursor: initial;
  }
  &.greyed {
    opacity: 0.4;
  }
  &.noPadding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .w {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    width: v-bind(wWidth);
    justify-content: v-bind(align) !important;
    flex-direction: row !important;
    .icon {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
      height: 20px;
      width: 20px;
      transition: transform 200ms;
      &.flip {
        transform: rotate(180deg);
      }
    }
    h3.label {
      white-space: nowrap;
      text-transform: capitalize;
      color: $g-900;
      opacity: 0;
      transition: all 400ms;
      &.hasLabel {
        opacity: 1;
      }
    }
  }
  &:hover {
    &.disabled {
      background-color: $p-900;
    }
    background-color: $p-800;
  }
  &.active {
    background-color: $p-300;
    box-shadow: $btn-shadow;
    transform: scale(1.02);
    .icon {
      height: 20px;
      width: 20px;
      color: white;
      opacity: 1;
    }
    h3.label {
      white-space: nowrap;
      text-transform: capitalize;
      font-weight: 900;
      color: white;
      opacity: 0;
      transition: all 400ms;
      &.hasLabel {
        opacity: 1;
      }
    }
  }

  &.primary {
    height: v-bind(btnHeight);
    width: v-bind(btnWidth);
    border-radius: 15px;
    cursor: pointer;
    transition: all 100ms;
    background-color: $p-300;
    &.disabled {
      cursor: initial;
    }
    .w {
      gap: 18px;
      width: v-bind(wWidth);
      justify-content: v-bind(align) !important;
      flex-direction: row !important;
      .icon {
        height: 20px;
        width: 20px;
        color: white;
        transition: transform 200ms;
        &.flip {
          transform: rotate(180deg);
        }
      }
      h3.label {
        text-transform: capitalize;
        color: white;
        opacity: 0;
        transition: all 400ms;
        &.hasLabel {
          opacity: 1;
        }
      }
    }
    &:hover {
      &.disabled {
        background-color: $p-300;
      }
      background-color: $p-400;
    }
    &.active {
      background-color: $p-300;
      box-shadow: $btn-shadow;
      transform: scale(1.02);
      .icon {
        height: 20px;
        width: 20px;
        color: white;
        opacity: 1;
      }
      h3.label {
        text-transform: capitalize;
        font-weight: 900;
        color: white;
        opacity: 0;
        transition: all 400ms;
        &.hasLabel {
          opacity: 1;
        }
      }
    }
  }
}
</style>