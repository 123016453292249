import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { firestorePlugin } from 'vuefire'
import AsyncComputed from 'vue-async-computed'
// import Trend from "vuetrend"
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import Vue2Editor from "vue2-editor";
import vTitle from 'vuejs-title'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { Laue } from 'laue';
import VueCookies from 'vue-cookies'
import * as VueSpinnersCss from "vue-spinners-css";
 

const toastOptions = {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 5,
  newestOnTop: true,
  position: POSITION.BOTTOM_CENTER,
};

Vue.use(VueSpinnersCss)

Vue.use(VueCookies);

Vue.use(Toast, toastOptions);

Vue.use(VueDatePicker, {
  lang: 'fr'
});


// Vue.use(Trend)
Vue.use(Laue)
Vue.use(firestorePlugin)
Vue.use(AsyncComputed)
Vue.use(Vue2Editor)
Vue.use(vTitle, {
  cssClass: "my-title-bubble",
  maxHeight: '500px'
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
