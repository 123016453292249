<template>
  <transition name="fade" mode="out-in">
    <div v-if="loaded" key="0" class="main">
      <div class="row">
        <div class="card" :class="{ danger: isToLong }">
          <h1 v-html="oldestSheetString" />
          <h3>Default de facturation</h3>
        </div>
        <div class="card" :class="{ danger: defaultOfEntry }">
          <h1 v-html="lastSheetEntryString"></h1>
          <h3>Default d'entrée</h3>
        </div>
        <div class="card">
          <h1 v-html="formatCash(dsoTotal)"></h1>
          <h3>DSO</h3>
        </div>
        <div class="card">
          <h1 v-html="formatCash(soldeQonto)"></h1>
          <h3>Solde Qonto</h3>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <h1 v-html="formatCash(totalTurnover)"></h1>
          <h3>Facturé</h3>
        </div>
        <div class="card">
          <h1 v-html="formatCash(totalTurnoverCashed)"></h1>
          <h3>Encaissé</h3>
        </div>
        <div class="card">
          <h1 v-html="formatCash(nonInvoicedTurnover)"></h1>
          <h3>Non-facturé</h3>
        </div>
        <div class="card">
          <h1 v-html="formatCash(nonCashed)"></h1>
          <h3>Non-encaissé</h3>
        </div>
      </div>
      <div id="graphRow" class="row twice">
        <div class="card graph">
          <la-cartesian
            v-if="showGraph"
            :autoresize="true"
            :data="cumulativeTrend"
            :height="graphHeight"
          >
            <la-line
              curve
              :animated="false"
              color="#2f59ff"
              :width="4"
              prop="val"
            ></la-line>
          </la-cartesian>
          <h3>Evolution du temps passé</h3>
        </div>
        <div class="card graph">
          <la-cartesian
            v-if="showGraph"
            :autoresize="true"
            :data="trendData"
            :height="graphHeight"
          >
            <la-line
              curve
              :animated="false"
              color="#2f59ff"
              :width="4"
              prop="val"
            ></la-line>
          </la-cartesian>
          <h3>Repartition dans le temps de l'activité</h3>
        </div>
      </div>
    </div>
    <div v-else key="1" class="main">
      <h1 class="title">Loading</h1>
    </div>
  </transition>
</template>

<script>
import { db } from "@/firebase";
import {
  timeFormat,
  cashFormat,
  formatLongTime,
  averageArray,
} from "@/utils/helpers";
import { invoiceStatus, sheetsStatus } from "@/utils/enums";

export default {
  data: () => ({
    loaded: false,
    usersRaw: [],
    clientsRaw: [],
    sheetsRaw: null,
    // sheetsRaw: [],
    missionsRaw: [],
    invoicesRaw: [],
    creditsRaw: [],
    qontoInfo: {},
    graphData: [],
    showGraph: false,
    graphHeight: 0,
    trendData: [],
    cumulativeTrend: [],
    turnoverYear: [],
  }),
  computed: {
    computedAccountUID() {
      return this.$cookies.get("accountuid");
    },
    // nonInvoicedTurnover() {
    //   const sheets = this.sheetsRaw.filter(
    //     (sheet) => sheet.status == sheetsStatus.CREATED
    //   );
    //   const total = sheets.reduce((p, v) => {
    //     const rate = this.houreRate(v.user);
    //     console.log({rate});
    //     // const rate =
    //     //   this.missionsRaw.filter((mission) => mission.uid == v.missionId)[0]
    //     //     .baseRate ?? 0;
    //     return p + v.time * rate;
    //   }, 0);
    //   return total;
    // },
    nonInvoicedTurnover() {
      const sheets = this.sheetsRaw.filter(
        (sheet) => sheet.status == sheetsStatus.CREATED
      );
      const total = sheets.reduce((p, v) => {
        const rate = this.latestRate(v);
        return p + v.time * rate;
      }, 0);
      // console.log({total});
      return total;
    },
    nonCashed() {
      const invoices = this.invoicesRaw.filter(
        (i) => i.status >= invoiceStatus.SENT && i.status < invoiceStatus.CASHED
      );
      const allCredits = this.creditsRaw;
      const t = invoices.reduce((p, v) => {
        const id = v.uid;
        const credits = allCredits.filter((c) => c.invoice == id);
        const totalCredits = credits.reduce((p, v) => {
          return p + Math.abs(v.amount);
        }, 0);
        return p + (v.amount - totalCredits);
      }, 0);
      return t;
    },
    dsoTotal() {
      return this.nonCashed + this.nonInvoicedTurnover;
    },
    oldestSheet() {
      const missions = this.missionsRaw;
      const sheets = this.sheetsRaw
        .filter((sheet) => {
          if (sheet.status != sheetsStatus.CREATED) {
            return false;
          }
          const missionId = sheet.missionId;
          if (missionId) {
            const mission = missions.filter((m) => m.uid == missionId)[0];
            let include = mission.includeInStats;
            if (include == undefined) {
              include = true;
            }
            console.log({ include });
            return include;
          } else {
            return true;
          }
        })
        .map((s) => {
          let ns = s;
          ns.date =
            typeof s.date.toDate === "function" ? s.date.toDate() : s.date;
          return ns;
        });
      console.log(sheets);
      sheets.sort((a, b) => a.date.getTime() - b.date.getTime());
      if (sheets.length < 1) {
        return {};
      } else {
        return sheets[0];
      }
    },
    oldestSheetString() {
      let oldestSheetDate;
      if (this.oldestSheet) {
        oldestSheetDate = this.oldestSheet.date;
      } else {
        oldestSheetDate = new Date();
      }
      const today = new Date();
      return this.getDays(oldestSheetDate, today);
    },
    rawBalance() {
      const val = this.qontoInfo.balance / 100;
      return val ? val : 0;
    },
    soldeQonto() {
      return this.rawBalance;
    },
    totalTime() {
      return this.sheetsRaw.reduce((p, v) => p + v.time, 0);
    },
    lastSheetEntry() {
      const sss = this.sheetsRaw.map((s) => {
        let ns = s;
        ns.date =
          typeof s.date.toDate === "function" ? s.date.toDate() : s.date;
        return ns;
      });
      sss.sort((a, b) => {
        if (a.date > b.date) {
          return -1;
        } else {
          return 1;
        }
      });
      const lastSheet = sss[0];
      if (lastSheet) {
        return lastSheet;
      } else {
        return null;
      }
    },
    lastSheetEntryString() {
      // const sDate = this.lastSheetEntry.date ?? new Date();
      const sDate = this.lastSheetEntry ? this.lastSheetEntry.date : new Date();
      const today = new Date();
      return this.getDays(sDate, today);
    },
    isToLong() {
      console.log(this.oldestSheet);
      const oldestSheetDate = this.oldestSheet.date;
      const today = new Date();
      const diff = Math.abs(today - oldestSheetDate);
      return diff > 2592000000;
    },
    defaultOfEntry() {
      // const lastEntry = this.lastSheetEntry.date ?? new Date();
      const lastEntry = this.lastSheetEntry
        ? this.lastSheetEntry.date
        : new Date();
      const today = new Date();
      const diff = Math.abs(today - lastEntry);
      return diff > 259200000;
    },
    totalTurnoverCashed() {
      const invoices = this.invoicesRaw.filter(
        (i) => i.status == invoiceStatus.CASHED
      );
      const allCredits = this.creditsRaw;
      const t = invoices.reduce((p, v) => {
        const id = v.uid;
        const credits = allCredits.filter((c) => c.invoice == id);
        const totalCredits = credits.reduce((p, v) => {
          return p + Math.abs(v.amount);
        }, 0);
        return p + (v.amount - totalCredits);
      }, 0);
      return t;
    },
    totalTurnover() {
      const t = this.invoicesRaw.reduce((p, v) => p + v.amount, 0);
      const c = this.creditsRaw.reduce((p, v) => p + Math.abs(v.amount), 0);
      console.log({ t });
      console.log({ c });
      return t - c;
    },
  },
  watch: {
    sheetsRaw(newVal) {
      console.log(newVal);
      if (newVal != null) {
        console.log("load data");
        this.loaded = true;
        this.$store.commit("setAppLoaded");
      }
    },
  },
  mounted() {
    const resize_ob = new ResizeObserver((entries) => {
      let rect = entries[0].contentRect;
      let height = rect.height;
      this.graphHeight = height - 80;
    });
    resize_ob.observe(document.querySelector("#graphRow"));
    setTimeout(() => {
      this.genTrendData();
      this.genTurnoverYear();
    }, 1000);
  },
  methods: {
    houreRate(userId) {
      const sheets = this.sheetsRaw.filter(
        (s) => s.user == userId && s.status > sheetsStatus.INVOICED
      );
      const nbr = sheets.length;
      const totalHourRate = sheets.reduce((p, v) => p + v.rate, 0);
      // Not production ready
      // Deal with base rate and don't hard-code baserate value (250)
      if (nbr == 0 || totalHourRate == 0) return 250;
      return totalHourRate / nbr;
    },
    latestRate(sheet) {
      const missionId = sheet.missionId;
      const clientId = sheet.clientUid;
      const userId = sheet.user;
      const clients = this.clientsRaw;
      const clientCanInvoice =
        clients.filter((c) => {
          return c.uid == clientId;
        })[0].canInvoice ?? true;
      if (!clientCanInvoice) {
        return 0;
      }
      if (missionId != "NO_MISSION" && missionId) {
        const searchSheets = this.sheetsRaw.filter(
          (s) =>
            s.status >= sheetsStatus.INVOICED &&
            s.user == userId &&
            s.missionId == missionId
        );
        searchSheets.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        const r = searchSheets[0] ? searchSheets[0].rate : 0;
        if (r == 0) {
          const searchSheetsDeep = this.sheetsRaw.filter(
            (s) =>
              s.status >= sheetsStatus.INVOICED &&
              s.user == userId &&
              s.clientUid == clientId
          );
          searchSheetsDeep.sort((a, b) => {
            if (a.date > b.date) {
              return -1;
            } else {
              return 1;
            }
          });
          const rr = searchSheetsDeep[0] ? searchSheetsDeep[0].rate : 0;
          if (rr == 0) {
            // console.log("did't find rate", sheet);
            return 0;
          } else {
            // console.log("found rate with client", rr);
            return rr;
          }
        } else {
          // console.log("found rate with mission", r);
          return r;
        }
      } else {
        const searchSheets = this.sheetsRaw.filter(
          (s) =>
            s.status >= sheetsStatus.INVOICED &&
            s.user == userId &&
            s.clientUid == clientId
        );
        searchSheets.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        console.log("no mission", searchSheets[0]);
        const rrr = searchSheets[0] ? searchSheets[0].rate : 0;
        return rrr > 0 ? rrr : 450;
      }
    },
    async refreshGraphs() {
      this.showGraph = false;
      await this.$nextTick();
      this.showGraph = true;
    },
    formatRange(time) {
      console.log(time);
      return formatLongTime(time);
    },
    getDays(startDate, endDate) {
      if (
        endDate == null ||
        endDate == undefined ||
        startDate == null ||
        startDate == undefined
      ) {
        return this.formatRange(-1);
      } else {
        const start = startDate;
        const end = endDate;
        console.log({ start }, { end });
        return this.formatRange((end - start) / 1000);
      }
    },
    getNextDay(date) {
      var dateFormatTotime = new Date(date);
      var increasedDate = new Date(dateFormatTotime.getTime() + 86400000);
      return increasedDate;
    },
    day(now) {
      var start = new Date(now.getFullYear(), 0, 0);
      var diff =
        now -
        start +
        (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
    },
    genTrendData() {
      let adder = 0;
      let points = [];
      let cumulativePoints = [];
      const shs = this.sheetsRaw.map((s) => {
        const newS = s;
        newS.date =
          typeof s.date.toDate === "function" ? s.date.toDate() : s.date;
        return newS;
      });
      shs.sort((a, b) => {
        if (a.date > b.date) {
          return -1;
        } else {
          return 1;
        }
      });
      const d1 = shs[0].date;
      const d2 = shs[shs.length - 1].date;
      const days = this.getNbrOfDays(d1, d2);
      let currentDate = d1;
      points.push(0);
      for (let i = 0; i < days; i++) {
        let localCount = 0;
        shs.forEach((sheet) => {
          if (this.day(sheet.date) == this.day(currentDate)) {
            localCount += sheet.time;
          }
        });
        adder += localCount;
        points.push(localCount);
        cumulativePoints.push(adder);
        currentDate = this.getNextDay(currentDate);
      }

      this.trendData = averageArray(points, 20).map((v) => {
        return { val: v };
      });
      this.cumulativeTrend = averageArray(cumulativePoints, 20).map((v) => {
        return {
          val: v,
        };
      });
      const el = document.getElementById("graphRow");
      this.graphHeight = el.getBoundingClientRect().height - 60;
      setTimeout(() => {
        this.showGraph = true;
      }, 100);
    },
    dayToYear(year, day) {
      return new Date(year, 0, day);
    },
    monthToYear(year, month) {
      return new Date(year, month, 0);
    },
    weekToYear(year, week) {
      var d = 1 + (week - 1) * 7;
      return new Date(year, 0, d);
    },
    genTurnoverYearOld() {
      const arr = [];
      const year = new Date().getFullYear();
      const startDate = new Date(new Date().getFullYear(), 0, 1);
      const endDate = new Date();
      let range = Math.ceil(
        (endDate.getTime() - startDate.getTime()) / 604800000
      );
      for (let i = 1; i <= range + 1; i++) {
        const limitDay = this.weekToYear(year, i);
        console.log(limitDay.toLocaleDateString());
        const sheets = this.sheetsRaw.filter(
          (sheet) => sheet.date < limitDay && sheet.status > 0
        );
        console.log(sheets);
        const invoices = this.invoicesRaw.filter(
          (invoice) => invoice.date.toDate() < limitDay && invoice.isOutSys
        );
        console.log(invoices);
        const ca = sheets.reduce((p, v) => p + v.time * v.rate, 0);
        const inv = invoices.reduce((p, v) => p + v.amount, 0);
        console.log(ca + inv);
        arr.push(ca + inv);
      }
      this.turnoverYear = arr;
    },
    genTurnoverYear() {
      const arr = [];
      const year = new Date().getFullYear();
      const startDate = new Date(new Date().getFullYear(), 0, 1);
      const endDate = new Date();
      let range = Math.ceil(
        (endDate.getTime() - startDate.getTime()) / 2629746000
      );
      console.log(range);

      for (let i = 1; i <= range; i++) {
        const limitDay = this.monthToYear(year, i);
        const minusDate = new Date(limitDay).setMonth(limitDay.getMonth() - 1);
        const sheets = this.sheetsRaw.filter(
          (sheet) =>
            sheet.date < limitDay && sheet.date >= minusDate && sheet.status > 0
        );
        const invoices = this.invoicesRaw.filter(
          (invoice) =>
            invoice.date.toDate() < limitDay &&
            invoice.date.toDate() >= minusDate &&
            invoice.isOutSys
        );
        const ca = sheets.reduce((p, v) => p + v.time * v.rate, 0);
        const inv = invoices.reduce((p, v) => p + v.amount, 0);
        console.log(ca);
        console.log(limitDay.toLocaleDateString());
        for (let j = 0; j < 30; j++) {
          arr.push(ca + inv);
        }
      }
      const min = arr.reduce((p, v) => {
        return p < v ? p : v;
      });
      const max = arr.reduce((p, v) => {
        return p > v ? p : v;
      });
      const avrg = (max - min) / 2;
      console.log(max, min);
      arr.unshift(min);
      arr.push(min);
      console.log(avrg);
      this.turnoverYear = arr;
    },
    getNbrOfDays(date_1, date_2) {
      let difference = date_1.getTime() - date_2.getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      console.log(totalDays);
      return totalDays;
    },
    sortByDate() {
      this.sortType = "date";
      if (this.isAsc) {
        this.sheets.sort((a, b) => {
          if (a.date.toDate() > b.date.toDate()) {
            return 1;
          } else {
            return -1;
          }
        });
      } else {
        this.sheets.sort((a, b) => {
          if (a.date.toDate() > b.date.toDate()) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      this.isAsc = !this.isAsc;
    },
    formatTime(time) {
      return timeFormat(time);
    },
    formatCash(amount) {
      return cashFormat(amount);
    },
  },
  firestore() {
    return {
      usersRaw: db
        .collection("users")
        .where("accounts", "array-contains", this.computedAccountUID),
      clientsRaw: db
        .collection("clients")
        .where("account", "==", this.computedAccountUID),
      sheetsRaw: db
        .collection("sheets")
        .where("account", "==", this.computedAccountUID),
      missionsRaw: db
        .collection("missions")
        .where("account", "==", this.computedAccountUID),
      invoicesRaw: db
        .collection("invoices")
        .where("account", "==", this.computedAccountUID),
      creditsRaw: db
        .collection("credits")
        .where("account", "==", this.computedAccountUID),
      qontoInfo: db.collection("utils").doc("qonto"),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-top: 0px;
  height: 100%;
  width: 100%;
  gap: 30px;
  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: 100%;
    &.twice {
      flex: 2;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    // height: 200px;
    height: 100%;
    width: 100%;
    // min-width: 200px;
    padding: 30px;
    box-shadow: $btn-shadow;
    border-radius: 17px;
    h1,
    h3 {
      margin: 0px;
    }
    h1 {
      font-size: $font-size-3;
    }
    h3 {
      font-size: $font-size-2;
      position: absolute;
      bottom: 20px;
    }
    &.graph {
      max-width: calc(50% - 15px);
      // height: 300px;
      // width: 600px;
      &.fullwidth {
        // width: 1230px;
      }
      .dum {
        height: 35px;
      }
      h3 {
        position: absolute;
        // top: 20px;
      }
    }
    &.danger {
      background-color: $danger;
      box-shadow: $danger-shadow;
      color: white;
    }
  }
}
</style>