<template>
  <div class="main">
    <div class="bar" :class="{ active: active }" />
  </div>
</template>

<script>
export default {
  props: {
    active: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 28px;
  min-width: 28px;
  background-color: white;
  border-radius: 5px;
  .bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 5px;
    transition: all 200ms;
    top: 4px;
    left: 4px;
    height: 20px;
    width: 8px;
    background-color: $p-300;
    &.active {
      left: 12px;
    }
  }
}
</style>