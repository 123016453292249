/**
 * @description: This function capitalizes the first letter of each word in a string.
 * @param {string} str 
 * @returns string
 */
const capitalize = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}


/**
 * @description This function averages an array of numbers.
 * @param {array} arr - The array to average.
 * @param {number} factor - The number of elements to average.
 * @returns {array}
 */
const averageArray = (arr, factor) => {
    let res = [];
    let i = 0;
    while (i < arr.length) {
        res.push(arr.slice(i, (i + factor <= arr.length ? i + factor : arr.length)).reduce((p, v) => p + v, 0) / ((i + factor <= arr.length ? i + factor : arr.length) - i));
        i += factor;
    }
    return res;
}


/**
 * @description This function returns a random string of 8 characters.
 * @returns {string} - A random string of 8 characters.
 */
const genKey = () => {
    return Math.random().toString().replace('0.', '').substring(0, 8);
}


/**
 * @description Returns a formatted string of the time in hours and minutes, or just minutes if under an hour.
 * @param {number} rawTime - The time in minutes
 * @returns {string} - The formatted time.
 */
const timeFormat = (rawTime) => {
    let pxSize = 14;
    let nodataPxSize = 14;
    let unit = 60;
    let time = rawTime * unit;
    if (rawTime > 0) {
        if (time % unit == 0) {
            if (time == 60) {
                return `${time / unit
                    }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heure</span>`;
            } else if (time == 0) {
                return `${time / unit
                    }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heure</span>`;
            } else {
                return `${time / unit
                    }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heures</span>`;
            }
        } else if (time <= 45) {
            return `${time}<span key="${genKey()}" style="font-size: ${pxSize}px;">min</span>`;
        } else {
            return `${(time - (time % unit)) / unit
                }<span key="${genKey()}" style="font-size: ${pxSize}px;"> h </span>${time % unit
                }<span key="${genKey()}" style="font-size: ${pxSize}px;"> m</span>`;
        }
    } else {
        return `<span key="${genKey()}" class="nodata" style="font-size: ${nodataPxSize}px;">Pas de temps passé</span>`
    }
}


/**
 * @description This function returns a formatted string of the the input rate.
 * @param {number} rate 
 * @returns {string}
 */
const formatRate = (rate) => {
    let pxSize = 14;
    let nodataPxSize = 14;
    if (rate < 0) {
        return `<span key="${genKey()}" class="nodata" style="font-size: ${nodataPxSize}px;">Pas de taux</span>`;
    } else {
        return `${rate}<span key="${genKey()}" style="font-size: ${pxSize}px;">%</span>`;
    }
}


/**
 * @description This function takes a string and appends a span with a font size of 14px and the text " €/h" to it.
 * @param {string} th 
 * @returns {string}
 */
const thFormat = (th) => {
    let pxSize = 14;
    return `${th}<span key="${genKey()}" style="font-size: ${pxSize}px;"> €/h</span>`;
}


/**
 * @description Returns a formatted string of the time in years and months, or just months if under a year.
 * @param {number} rawTime - The time in minutes
 * @returns {string} - The formatted time.
 */
const formatLongTime = (rawTime) => {
    let pxSize = 14;
    let nodataPxSize = 14;
    if (rawTime == 0) {
        return `<span key="${genKey()}" class="nodata" style="font-size: ${nodataPxSize}px;">Durée indéfinie</span>`;
    }
    let returnValue;
    let daysNbr = Math.floor(rawTime / 86400);
    let oneMonth = 30;
    let oneYear = 365;
    if (rawTime == -1) {
        return `<span key="${genKey()}" class="nodata" style="font-size: ${nodataPxSize}px;">Durée indéfinie</span>`;
    } else if (daysNbr < oneMonth) {
        returnValue = `${daysNbr}<span key="${genKey()}" style="font-size: ${pxSize}px;"> Jours</span>`
    } else if (daysNbr >= oneMonth && daysNbr < oneYear) {
        returnValue = `${Math.round(daysNbr / oneMonth)}<span key="${genKey()}" style="font-size: ${pxSize}px;"> Mois</span>`
    } else {
        const years = ((daysNbr) - daysNbr % oneYear) / oneYear;
        const months = Math.round((daysNbr % oneYear) / oneMonth);
        if (years == 1) {
            returnValue = `${years}<span key="${genKey()}" style="font-size: ${pxSize}px;"> An </span>${months}<span key="${genKey()}" style="font-size: ${pxSize}px;"> Mois</span>`
        } else {
            returnValue = `${years}<span key="${genKey()}" style="font-size: ${pxSize}px;"> Ans </span>${months}<span key="${genKey()}" style="font-size: ${pxSize}px;"> Mois</span>`
        }
    }
    return returnValue;
}


/**
 * @description Returns a formatted string of the time in hours and minutes, or just minutes if under an hour.
 * @param {number} rawTime - The time in minutes
 * @returns {string} - The formatted time.
 */
const timeFormatSmall = (rawTime) => {
    let pxSize = 14;
    let nodataPxSize = 14;
    let unit = 60;
    let time = rawTime * unit;
    if (rawTime > 0) {
        if (time % unit == 0) {
            if (time == 60) {
                return `${time / unit
                    }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heure</span>`;
            } else if (time == 0) {
                return `${time / unit
                    }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heure</span>`;
            } else {
                return `${time / unit
                    }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heures</span>`;
            }
        } else if (time <= 45) {
            return `${time}<span key="${genKey()}" style="font-size: ${pxSize}px;">min</span>`;
        } else {
            return `${(time - (time % unit)) / unit
                }<span key="${genKey()}" style="font-size: ${pxSize}px;"> h </span>${time % unit
                }<span key="${genKey()}" style="font-size: ${pxSize}px;"> m</span>`;
        }
    } else {
        return `<span key="${genKey()}" class="nodata" style="font-size: ${nodataPxSize}px;">Pas de temps passé</span>`
    }
}


/**
 * @description Formats a number to a currency string with euro symbol
 * @param {number} amount
 * @returns {string}
 */
const cashFormat = (amount) => {
    var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
    });
    let pxSize = 14;
    let nodataPxSize = 14;
    if (amount > 0) {
        return `${formatter.format(amount.toFixed(2)).substring(0, formatter.format(amount.toFixed(2)).length - 1).replaceAll(',', '.')}<span key="${genKey()}" style="font-size: ${pxSize}px;"> €</span>`;
    } else {
        return `<span key="${genKey()}" class="nodata" style="font-size: ${nodataPxSize}px;">Zéro</span>`
    }
}


/**
 * @description This function takes the hourly rate and returns a formatted string of that rate
 * @param {number} hourly hourly rate
 * @returns {string} hourly rate formatted
 */
const formatHourly = (hourly) => {
    let pxSize = 14;
    const value = hourly.toFixed(2);
    return `${value}<span key="${genKey()}" style="font-size: ${pxSize}px;"> €/h</span>`;
}


/**
 * @description: This function takes in a time in minutes and returns a formatted string of that time
 * @param {number} rawTime
 * @returns {string} formatted string of time in hours and minutes
 */
const timeFormatCondensed = (rawTime) => {
    let pxSize = 14;
    let unit = 60;
    let time = rawTime * unit;
    if (time % unit == 0) {
        if (time == 60) {
            return `${time / unit
                }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heure</span>`;
        } else if (time == 0) {
            return `${time / unit
                }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heure</span>`;
        } else {
            return `${time / unit
                }<span key="${genKey()}" style="font-size: ${pxSize}px;"> heures</span>`;
        }
    } else if (time <= 45) {
        return `${time}<span key="${genKey()}" style="font-size: ${pxSize}px;">min</span>`;
    } else {
        return `${(time - (time % unit)) / unit
            }<span key="${genKey()}" style="font-size: ${pxSize}px;">h</span>${time % unit
            }<span key="${genKey()}" style="font-size: ${pxSize}px;">m</span>`;
    }
}


/**
 * @description Returns a promise that resolves after the given number of milliseconds.
 * @param {number} ms - The number of milliseconds to wait before resolving.
 * @returns {Promise}
 */
const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}


/**
 * @description - This function takes in a string and returns a string with all diacritics removed.
 * @param {string} string - The string to normalize.
 * @returns {string} - The normalized string.
 */
const norm = (string) => {
    return string
        .normalize("NFD") // Normalizes string to the Normalization Form D.
        .replaceAll('.', '') // Removes all periods from string.
        .replaceAll('€', '') // Removes all euro signs from string.
        .replace(/<[^>]*>/gi, '') // Removes all HTML tags from string.
        .replace(/\p{Diacritic}/gu, "") // Removes all diacritics from string.
        .replace(/\s/g, '') // Removes all whitespace from string.
        .toUpperCase() // Converts string to uppercase.
}


/**
 * @description Clamps a value between a minimum and maximum value.
 * @param {number} value The value to clamp.
 * @param {number} min The minimum value.
 * @param {number} max The maximum value.
 * @returns {number} The clamped value.
 */
const clampToRange = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
}


/**
 * 
 * @param {string} name 
 * @returns {string} cookie value
 * @description This function takes in a cookie name and returns the value of that cookie
 */
const getCookie = (name) => {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return '';
}

/**
 * @description This function takes in a number and returns a string of that number with a leading 0 if it is less than 10
 * @param {number} num
 * @returns {string} formatted number
 */
const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

/**
 * @description This function takes in a date and returns a string of that date in the format DD/MM/YYYY    
 * @param {Date} date
 * @returns {string} formatted date
 * @example
 */
const formatDate = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}

export {
    capitalize,
    timeFormat,
    cashFormat,
    timeFormatCondensed,
    timeFormatSmall,
    timeout,
    norm,
    formatLongTime,
    formatRate,
    thFormat,
    formatHourly,
    averageArray,
    clampToRange,
    getCookie,
    padTo2Digits,
    formatDate,
}